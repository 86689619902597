<template>
  <div class="system-notice-page">
    <div v-for="(item, index) in systemNoticeData" :key="index">
      <div class="title" v-if="item.time">{{ item.time }}</div>
      <ul class="system-notice-list" v-if="item.children">
        <li class="system-notice-item" v-for="child in item.children" :key="child.id">
          <div>
            <span class="content">【{{ child.action }}】</span>
            <span class="name">{{ child.name }}</span>
          </div>
          <div class="time">{{ child.time }}</div>
        </li>
      </ul>
    </div>
    <div style="text-align: center; margin-top: 30px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[10, 30, 40, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.count"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getSystemNoticeList } from '../../../common/setting';
export default {
  name: 'systemNoticePage',
  data() {
    return {
      systemNoticeData: [],
      page: {
        page: 1,
        pageSize: 10,
        count: 0,
      },
    };
  },
  created() {
    this.querySystemNoticeData();
  },
  methods: {
    // 查询系统通知数据
    querySystemNoticeData() {
      let params = {
        page: this.page.page,
        len: this.page.pageSize,
      };
      getSystemNoticeList(params).then((res) => {
        let { code, result } = res;
        if (code === 200) {
          this.systemNoticeData = result.ApplyInfo;
          this.page.count = result.count;
        }
      });
    },
    // 处理分页
    handleSizeChange(size) {
      this.page.pageSize = size;
      this.querySystemNoticeData();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.querySystemNoticeData();
    },
  },
};
</script>

<style lang="scss" scoped>
.system-notice-page {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 15px 20px;
  background-color: #fff;
  font-size: 15px;
  color: #353535;

  .title {
    width: 96%;
    height: 80px;
    margin: 0 auto;
    border-bottom: 1px solid #d2d2d2;
    line-height: 80px;
  }

  .system-notice-list {
    width: 100%;
    height: 100%;

    .system-notice-item {
      box-sizing: border-box;
      width: 96%;
      height: 80px;
      margin: 0 auto;
      padding: 10px 0;
      border-bottom: 1px solid #d2d2d2;
    }

    .content {
      color: #1790ff;
    }

    .el-button {
      margin-left: 20px;
    }

    .time {
      margin: 15px 0 0 5px;
      color: #b0b0b0;
    }
  }
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #29303e;
}
</style>
