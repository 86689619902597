<template>
  <div class="system-message-page">
    <el-tabs tab-position="top" style="height: 100%">
      <el-tab-pane label="系统通知">
        <systemNoticePage></systemNoticePage>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import systemNoticePage from './components/systemNotice.vue';

export default {
  components: {
    systemNoticePage,
  },
};
</script>

<style lang="scss" scoped>
.system-message-page {
  box-sizing: border-box;
  height: 100%;
  padding-top: 20px;
  background-color: #fff;

  ::v-deep .el-tabs__header {
    width: 56px;
    margin: 0 auto;
  }
}
</style>
